import { useState, useEffect } from "react";
// services
import User from "../services/user";
import Gateway from "../services/gateway";
import Devices from "../services/devices";
// types
import type { DeviceObjs } from "../types/device";

const useDevices = () => {
	const [loading, setLoading] = useState(true);
	const [devices, setDevices] = useState(Devices.get());

	useEffect(() => {
		const loadDevices = () => {
			Devices.getDevices((errors, newDevices) => {
				setDevices((errors === null) ? newDevices : []);
				setLoading(false);
			});
		};

		const handleUserReady = () => {
			// setLoading(true);
			loadDevices();
		};

		const handleSelectedGatewayChanged = (/*gateway*/) => {
			setLoading(true);
			loadDevices();
		};

		const handleDevicesReset = () => {
			setLoading(true);
			setDevices(Devices.get());
		};

		const handleDevicesChanged = (changedDevices: DeviceObjs) => {
			setDevices(changedDevices);
		};

		loadDevices();

		User.on("ready", handleUserReady);
		Gateway.on("selectedGatewayChanged", handleSelectedGatewayChanged);
		Devices.on("reset", handleDevicesReset);
		Devices.on("changed", handleDevicesChanged);

		return () => {
			User.off("ready", handleUserReady);
			Gateway.off("selectedGatewayChanged", handleSelectedGatewayChanged);
			Devices.off("reset", handleDevicesReset);
			Devices.off("changed", handleDevicesChanged);
		};
	}, []);

	return {
		loading: loading,
		devices: devices,
	} as const;
};

export default useDevices;
