//replacement for capacacitor - preferences using the same methods
//is used to write in specific named userDefaults, e.g. for AppGroups to share between Extensions
//old: setting group for iOS just added {group}.{key} instead of writing in a different Suite

import { registerPlugin } from "@capacitor/core";

import type { CustomUserDefaultsPlugin } from "./definitions";

const CustomUserDefaults = registerPlugin<CustomUserDefaultsPlugin>("CustomUserDefaults");

export default CustomUserDefaults;
