import { useState, useEffect, useContext, createContext } from "react";
import PropTypes from "prop-types";
import { Capacitor } from "@capacitor/core";
// contexts
import { useServerOption } from "./ServerOptionContext";
// services
import Gateway from "../services/gateway";
import Gateways from "../services/gateways";
import { storeGatewayPreferences, clearGatewayPreferences } from "../services/gatewayPreferences";
// types
import type { ReactElement } from "react";
import type { Gateway as GatewayT } from "../types/gateway";

const GatewayContext = createContext<GatewayT | undefined>(undefined);

if (process.env.NODE_ENV !== "production") {
	GatewayContext.displayName = "GatewayContext";
}

export const useGateway = () => (useContext(GatewayContext));

type Props = {
	children: ReactElement;
};

export const GatewayProvider = ({ children }: Props) => {
	const { channel } = useServerOption();

	const [gateway, setGateway] = useState(Gateway.selectedGateway);

	useEffect(() => {
		const handleGatewayChanged = (newGateway: GatewayT | undefined) => {
			setGateway(newGateway);
		};

		Gateway.on("setDefault", handleGatewayChanged);
		Gateways.on("gatewayDetailsUpdated", handleGatewayChanged);

		return () => {
			Gateway.off("setDefault", handleGatewayChanged);
			Gateways.off("gatewayDetailsUpdated", handleGatewayChanged);
		};
	}, []);

	useEffect(() => {
		if (Capacitor.isNativePlatform() && channel === "hornbach") { // TODO: remove "hornbach" check if other themes also have smart widget
			if (gateway?.id && gateway.name && gateway.status) {
				storeGatewayPreferences(gateway.id, gateway.name, gateway.status).catch((error) => {
					console.error("storeGatewayPreferences failed", error);
				});
			} else {
				clearGatewayPreferences().catch((error) => {
					console.error("clearGatewayPreferences failed", error);
				});
			}
		}
	}, [channel, gateway?.id, gateway?.name, gateway?.status]);

	return (
		<GatewayContext.Provider value={gateway}>
			{children}
		</GatewayContext.Provider>
	);
};

GatewayProvider.propTypes = {
	children: PropTypes.element.isRequired,
};
